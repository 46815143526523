import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faDoNotEnter, faKey } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpgradeAPIModel, UpgradeTitle } from 'src/@types/common';
import { useNutakuContext } from 'src/context/Nutaku.Context';
import { useResourcesContext } from 'src/context/ResourcesContext';
import { useUserContext } from 'src/context/User.context';
import {
  SubscriptionLevel,
  SubscriptionPricesConfig,
} from 'src/services/API/ResourcesAPI';
import { SubscriptionProvider } from 'src/services/API/UserAPI';
import { cn } from 'src/shared/utils';
import {
  HornyButton,
  HornyButtonBG,
  HornyButtonHeight,
} from '../Shared/HornyButton/HornyButton';
import { Icon, IconSize, IconSrc } from '../Shared/Icon/Icon';
import { SheenBlock } from '../Shared/SheenBlock';
import { useBenefits } from './Benifits';
import './index.css';
import { BoostyProviderFlow } from './SubscriptionProviderFlow/BoostyProviderFlow';
import { G2AProviderFlow } from './SubscriptionProviderFlow/G2AProviderFlow';
import { NutakuProviderFlow } from './SubscriptionProviderFlow/NutakuProviderFlow';
import { PatreonProviderFlow } from './SubscriptionProviderFlow/PatreonProviderFlow';
import { PriceButtonFlow } from './SubscriptionProviderFlow/PriceButtonFlow';
import { SubscribeStarProviderFlow } from './SubscriptionProviderFlow/SubscribeStarProviderFlow';
import { NutakuPrice } from './Subscriptions';
import { UpgradeIcons } from './Upgrades/Upgrade';

export const levelIconsSrc = {
  [SubscriptionLevel.Friend]: IconSrc.Friend,
  [SubscriptionLevel.Boyfriend]: IconSrc.SubscriptionBoyfriend,
  [SubscriptionLevel.Daddy]: IconSrc.SubscriptionDaddy,
  [SubscriptionLevel.Boss]: IconSrc.SubscriptionBoss,
  [SubscriptionLevel.Master]: IconSrc.SubscriptionMaster,
};

export type SubscriptionProps = {
  level: SubscriptionLevel;
  selectedLevel: boolean;
  subscriptionData: SubscriptionPricesConfig;
  selectedProvider: SubscriptionProvider | null;
  isYearly?: boolean;
  nutakuPrice: NutakuPrice;
  upgrades: UpgradeAPIModel[];
};

export const discountCount = (small: number, big: number) => {
  return Math.floor((1 - small / big) * 100);
};

export function Subscription({
  level,
  selectedLevel,
  subscriptionData,
  selectedProvider,
  isYearly,
  nutakuPrice,
  upgrades = [],
}: SubscriptionProps) {
  console.log({ subscriptionData });
  const { user } = useUserContext();
  const { MappedBenefits } = useBenefits();
  const { t } = useTranslation();
  const { isNutaku, nutakuEnv, setIsPaymentFrame } = useNutakuContext();
  const levelIconSrc = levelIconsSrc[level];
  const [subscriptionProvider, setSubscriptionProvider] =
    useState<SubscriptionProvider | null>(null);
  const { prices } = useResourcesContext();
  const [priceButtonClicked, setPriceButtonClicked] = useState(false);
  useEffect(() => {
    setSubscriptionProvider(null);
    setPriceButtonClicked(false);
  }, [isYearly]);
  const levelRegularPercentage = {
    [SubscriptionLevel.Friend]: isYearly
      ? prices?.Subscriptions.Friend.PriceYear
      : prices?.Subscriptions.Friend.Price!,
    [SubscriptionLevel.Boyfriend]: isYearly
      ? prices?.Subscriptions.Boyfriend.PriceYear
      : prices?.Subscriptions.Boyfriend.Price!,
    [SubscriptionLevel.Daddy]: 25,
    [SubscriptionLevel.Boss]: 60,
    [SubscriptionLevel.Master]: 80,
  };

  const subscriptionProvidersPreview = (
    <>
      {/* <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.Patreon)}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Patreon} size={IconSize.Small} />
        {t('Pay with Patreon')}
  </HornyButton> */}

      {!isYearly && (
        <HornyButton
          onClick={() => setSubscriptionProvider(SubscriptionProvider.Boosty)}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.Boosty} size={IconSize.Small} />
          {t('Pay with Boosty')}
        </HornyButton>
      )}

      <HornyButton
        onClick={() => setSubscriptionProvider(SubscriptionProvider.G2A)}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
        className="w-max-content"
      >
        {/* <Icon src={IconSrc.G2A} size={IconSize.Small} /> */}
        <FontAwesomeIcon icon={faKey} />
        {t('Pay with G2A')}
      </HornyButton>

      <HornyButton
        onClick={() => setPriceButtonClicked(false)}
        bg={HornyButtonBG.Red}
        height={HornyButtonHeight.Shorter}
        className="w-max-content"
      >
        <Icon src={IconSrc.Back} size={IconSize.Small} />
        {t('Back')}
      </HornyButton>

      {/* <HornyButton
       {/* <HornyButton
         onClick={() => setSubscriptionProvider(SubscriptionProvider.Card)}
        bg={HornyButtonBG.Red}
        className="w-max-content"
        height={HornyButtonHeight.Shorter}
      >
        <Icon src={IconSrc.Card} size={IconSize.Small} />
        {t('Pay with Card')}
</HornyButton> */}
    </>
  );

  const subscriptionProviderElement = (
    el: JSX.Element,
    provider: SubscriptionProvider
  ) => {
    console.log({
      tutorialHrefs,
      provider,
    });
    return (
      <>
        {/* <div className="d-flex flex-column gap-3 align-items-center w-100"> */}
        {el}
        {/* <div className="d-flex  gap-3 align-items-center w-100"> </div>
        <div className="d-flex  gap-3 align-items-center w-100"> */}
        <HornyButton
          onClick={() => setSubscriptionProvider(null)}
          bg={HornyButtonBG.Red}
          height={HornyButtonHeight.Shorter}
          className="w-max-content"
        >
          <Icon src={IconSrc.Back} size={IconSize.Small} />
          {t('Back to list')}
        </HornyButton>
        <a href={tutorialHrefs[provider]} target="_blank">
          <Icon className="ms-2" src={IconSrc.Info} />
        </a>
        {/* </div>
      </div> */}
      </>
    );
  };

  const subscriptionProviderInteraction = {
    [SubscriptionProvider.Card]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={false}
        mockCard={true}
      />
    ),
    [SubscriptionProvider.Patreon]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={false}
        mockCard={false}
      />
    ),
    [SubscriptionProvider.SubscribeStar]: () => (
      <SubscribeStarProviderFlow
        subscriptionId={subscriptionData.Providers.SubscribeStar.Id}
        subscriptionLevel={level}
        selectedProvider={false}
      />
    ),
    [SubscriptionProvider.Boosty]: () => (
      <BoostyProviderFlow
        subscriptionLevel={level}
        selectedProvider={false}
        subscriptionId={subscriptionData.Providers.Boosty.Id}
      />
    ),
    [SubscriptionProvider.G2A]: () => (
      <G2AProviderFlow
        subscriptionLevel={level}
        selectedProvider={false}
        isYearly={isYearly}
        subscriptionId={
          isYearly
            ? subscriptionData.Providers.G2A.YearId
            : subscriptionData.Providers.G2A.Id
        }
      />
    ),
  };

  const selecteProviderIntegration = {
    [SubscriptionProvider.Card]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.Patreon}
        mockCard={true}
      />
    ),
    [SubscriptionProvider.Patreon]: () => (
      <PatreonProviderFlow
        subscriptionId={subscriptionData.Providers.Patreon.Id}
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.Patreon}
        mockCard={false}
      />
    ),
    [SubscriptionProvider.SubscribeStar]: () => (
      <SubscribeStarProviderFlow
        subscriptionId={subscriptionData.Providers.SubscribeStar.Id}
        subscriptionLevel={level}
        selectedProvider={
          selectedProvider === SubscriptionProvider.SubscribeStar
        }
      />
    ),
    [SubscriptionProvider.Boosty]: () => (
      <BoostyProviderFlow
        subscriptionId={subscriptionData.Providers.Boosty.Id}
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.Boosty}
      />
    ),
    [SubscriptionProvider.G2A]: () => (
      <G2AProviderFlow
        subscriptionId={
          isYearly
            ? subscriptionData.Providers.G2A.YearId
            : subscriptionData.Providers.G2A.Id
        }
        subscriptionLevel={level}
        selectedProvider={selectedProvider === SubscriptionProvider.G2A}
        isYearly={isYearly}
      />
    ),
  };

  const energyRefill = {
    [SubscriptionLevel.Friend]: `${prices?.NoSubscription.Energy} → ${prices?.Subscriptions.Friend.Energy} ${t('daily refill')}`,
    [SubscriptionLevel.Boyfriend]: `${prices?.NoSubscription.Energy} → ${prices?.Subscriptions.Boyfriend.Energy} ${t('daily refill')}`,
    [SubscriptionLevel.Daddy]: `${prices?.NoSubscription.Energy} → ${prices?.Subscriptions.Daddy.Energy} ${t('daily refill')}`,
    [SubscriptionLevel.Boss]: t('Unlimited energy'),
    [SubscriptionLevel.Master]: t('Unlimited energy'),
  };

  const messagesRefill = {
    [SubscriptionLevel.Friend]: `${prices?.NoSubscription.Messages} → ${prices?.Subscriptions.Friend.Messages} ${t('daily refill')}`,
    [SubscriptionLevel.Boyfriend]: t('Unlimited messages'),
    [SubscriptionLevel.Daddy]: t('Unlimited messages'),
    [SubscriptionLevel.Boss]: t('Unlimited messages'),
    [SubscriptionLevel.Master]: t('Unlimited messages'),
  };

  const hasUpgrade = (upgrade: UpgradeTitle): boolean => {
    return user?.Upgrades.find((u) => u.Title === upgrade) ? true : false;
  };

  const subscriptionDaysLeft = user?.Subscription?.Ends
    ? Math.ceil(
        (new Date(user?.Subscription?.Ends).getTime() - Date.now()) /
          (1000 * 60 * 60 * 24)
      )
    : null;

  const newSubscriptionDays = isYearly ? 365 : 30;

  const downgrade = !isYearly && user?.Subscription.IsYearly;

  const canBuy = downgrade
    ? false
    : !selectedLevel
      ? true
      : !subscriptionDaysLeft
        ? true
        : newSubscriptionDays - subscriptionDaysLeft > 3;

  return (
    <div
      className={cn(
        'tw-w-full tw-bg-gradient-to-b tw-from-horny-orange-400 tw-to-horny-orange-500 tw-py-6 tw-px-6 tw-rounded-xl  tw-shadow-bezel tw-relative tw-overflow-hidden',
        selectedLevel
          ? 'tw-border tw-border-horny-orange-200'
          : 'tw-border-[0.5px] tw-border-horny-orange-600'
      )}
    >
      {!selectedLevel && <SheenBlock />}
      {selectedLevel && (
        <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full tw-bg-horny-orange-600 tw-bg-opacity-30 tw-items-center tw-justify-center tw-rounded-md tw-my-3 tw-py-3">
          <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center tw-text-3xl tw-font-bold">
            <FontAwesomeIcon icon={faCircleCheck} />
            <div>{t('Active')}</div>
          </div>
          <div>
            <span className="tw-font-bold">{t('Ends')}: </span>
            <span>{user?.Subscription.Ends?.toString().split('T')[0]}</span>
          </div>
        </div>
      )}
      {!isNutaku && isYearly && (
        <div className="tw-flex tw-items-center tw-rounded-bl-xl tw-px-4 tw-py-2 tw-bg-horny-orange-700 tw-bg-opacity-25 tw-font-bold tw-text-xs tw-text-nowrap tw-w-fit tw-absolute tw-top-0 tw-right-0 tw-leading-none tw-capitalize">
          {discountCount(
            subscriptionData.PriceYear / 12,
            subscriptionData.Price
          )}
          {t('% discount')}
        </div>
      )}
      {isNutaku &&
        (isYearly ||
          nutakuPrice.price !== prices?.Subscriptions[level].NutakuPrice) && (
          <div className="tw-flex tw-items-center tw-rounded-bl-xl tw-px-4 tw-py-2 tw-bg-horny-orange-700 tw-bg-opacity-25 tw-font-bold tw-text-xs tw-text-nowrap tw-w-fit tw-absolute tw-top-0 tw-right-0 tw-leading-none tw-capitalize">
            {isYearly
              ? discountCount(
                  nutakuPrice.price_year / 12,
                  prices?.Subscriptions[level].NutakuPrice!
                )
              : discountCount(
                  nutakuPrice.price,
                  prices?.Subscriptions[level].NutakuPrice!
                )}
            {t('% discount')}
          </div>
        )}

      <div
        className={'tw-flex tw-flex-col tw-z-20 tw-position-relative tw-gap-5'}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-white tw-border-opacity-20 tw-pb-3">
          <div className="tw-flex tw-gap-2 tw-flex-row tw-items-center">
            <Icon src={levelIconSrc} size={IconSize.XL} />
            <div className="tw-font-bold tw-text-3xl tw-leading-none tw-w-fit">
              {t(level as any)}
            </div>
            {isNutaku && (
              <div className="tw-leading-none tw-w-fit tw-pt-1">
                {'+'}
                {isYearly ? '365' : '30'} {t('days from today')}
              </div>
            )}
          </div>
          <div className="tw-flex tw-flex-row tw-gap-3">
            {/* {!isNutaku && (
              // <div className="tw-font-bold tw-text-xl">
              //   ${isYearly ? subscriptionData.PriceYear : subscriptionData.Price}
              // </div>
            )} */}
            {/* {level !== SubscriptionLevel.Boyfriend &&
              level !== SubscriptionLevel.Friend &&
              !isYearly  &&
              !isNutaku && (
                <div className="tw-line-through tw-opacity-50 tw-text-lg">
                  ${levelRegularPercentage[level]}
                </div>
              )} */}
            {/* { isYearly  &&
              !isNutaku && (
                <div className="tw-line-through tw-opacity-50 tw-text-lg">
                  ${subscriptionData.Price * 12}
                </div>
              )} */}
          </div>
        </div>

        <div className="tw-grid tw-grid-cols-2 tw-gap-3">
          {MappedBenefits[level].map((benefit, i) => {
            return (
              <div
                key={benefit.title}
                className={cn(
                  'tw-flex tw-items-center gap-2',
                  benefit.disabled && 'tw-opacity-50'
                )}
              >
                <FontAwesomeIcon
                  icon={benefit.icon ? benefit.icon : faDoNotEnter}
                  size="xs"
                />
                <div
                  className={cn('tw-text-base', benefit.bold && 'tw-font-bold')}
                >
                  {benefit.title ? benefit.title : 'Unavailable'}
                </div>
              </div>
            );
          })}
        </div>

        {level === SubscriptionLevel.Master && (
          <div className="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-justify-center tw-border-t tw-border-white tw-border-opacity-20">
            <div className="tw-font-bold tw-pt-2">
              {t('Includes upgrades:')}{' '}
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-3">
              {upgrades.map(
                (upgrade) =>
                  upgrade.title !== 'GeneralUp' &&
                  upgrade.mixin_to_master && (
                    <div
                      className={cn(
                        'tw-flex tw-flex-row tw-gap-2 tw-items-center tw-leading-tight',
                        {
                          'tw-opacity-75': hasUpgrade(upgrade.title),
                        }
                      )}
                    >
                      <div className="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-shrink-0 tw-aspect-square">
                        <FontAwesomeIcon icon={UpgradeIcons[upgrade.title]} />
                      </div>
                      <div className={cn('tw-opacity-75')}>
                        {hasUpgrade(upgrade.title) && (
                          <span className="tw-font-bold">Active: </span>
                        )}
                        {t(upgrade.title)}
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        )}

        {!isNutaku ? (
          selectedProvider === 'Boosty' && isYearly ? null : (
            <div className="tw-gap-3 tw-flex tw-items-center tw-justify-center tw-flex-wrap tw-z-20 ">
              {!priceButtonClicked && !selectedLevel ? (
                <PriceButtonFlow
                  isYearly={!!isYearly}
                  onClick={() => setPriceButtonClicked(true)}
                  price={subscriptionData.Price}
                  priceYear={subscriptionData.PriceYear}
                />
              ) : (
                !selectedLevel &&
                (selectedProvider
                  ? selecteProviderIntegration[selectedProvider]()
                  : !subscriptionProvider
                    ? subscriptionProvidersPreview
                    : subscriptionProviderElement(
                        subscriptionProviderInteraction[subscriptionProvider](),
                        subscriptionProvider!
                      ))
              )}
            </div>
          )
        ) : canBuy ? (
          <NutakuProviderFlow
            selectedProvider={true}
            isYearly={isYearly as boolean}
            subscriptionLevel={level}
            subscriptionId={''}
            subscriptionData={subscriptionData}
            nutakuPrices={nutakuPrice}
          />
        ) : null}
      </div>
    </div>
  );
}

export const tutorialHrefs = {
  [SubscriptionProvider.Card]:
    'https://temptationgames.com/guides-to-connect-accounts',
  [SubscriptionProvider.Patreon]:
    'https://temptationgames.com/guides-to-connect-accounts',
  [SubscriptionProvider.Boosty]:
    'https://temptationgames.com/guides-to-connect-accounts',
  [SubscriptionProvider.SubscribeStar]:
    'https://temptationgames.com/5f5a3b4ccabb4e3db6d70d9a7bf36958',
  [SubscriptionProvider.G2A]:
    'https://temptationgames.com/guides-to-connect-accounts',
};
